.booking {
  z-index: 2;
  position: relative;
  //top: 100%;
  //right: 0;
  //left: 0;
  display: flex;
  flex-flow: column-reverse;
  justify-content: stretch;
  margin-top: auto;

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text {
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-container {
    display: none;
    //bottom: 0;
    //right: 0;
    //left: 0;
    //position: absolute;
    //max-height: var(--app-height);
    //transition: transform .3s;
    //transform: translateY(calc(100% + 100px));

    .container {
      border-radius: 20px 20px 0 0;
      box-shadow: 0 -5px 50px rgba(0, 0, 0, 0.75);
      background: linear-gradient(180.05deg, #081a90 -4.3%, #000a4e 99.96%);
    }

    &::before {
      transition: opacity .3s;
      content: '';
      z-index: 3;
      position: absolute;
      top: -400px;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0;
      pointer-events: none;
      background: rgba(0, 0, 0, 0.75);
    }

    &.__overlay {
      &::before {
        pointer-events: all;
        opacity: 1;
      }
    }

    &.__open {
      display: block;
      //transform: translateY(0);

      ~ .__open {
        pointer-events: none;
        position: fixed;
        z-index: 3;
        bottom: 0;
        left: 0;
        right: 0;

        &::before {
          opacity: 0 !important;
        }
      }
    }
  }
}
