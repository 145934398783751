.order {

  .title {
    color: #fff;
  }

  .text {
    color: rgba(255, 255, 255, .5);
  }

  &-top {
    z-index: 2;
    position: sticky;
    top: -1px;
    padding: 18px 16px;
    transition: padding-top .15s;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    &.__pinned {
      padding-top: 40px;
      backdrop-filter: blur(6px);
    }
  }

  &-info {
    display: grid;
    gap: 18px;
    grid-template-columns: 1fr 1fr;
    color: #fff;

    &__time {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      font: 700 40px/48px $SFProDisplay;
    }

    &__status {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      font: 700 24px/29px $SFProDisplay;
    }

    &__info {
      padding-top: 10px;
      font-size: 14px;
      line-height: 16px;
      color: rgba(255, 255, 255, .75);

      a {
        color: inherit;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &__help {

      .btn {
        text-decoration: none;
        border-radius: 8px;
        padding: 20px 5px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
      }

    }

    &__item {
      padding: 16px;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 12px;
      display: flex;
      flex-flow: column;

      p {
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 1px;
        margin-bottom: 6px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, .5);
      }
    }
  }

  &-survey {

    &__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 16px;

      .btn {
        text-decoration: none;
        border-radius: 8px;
        padding: 20px 5px;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.5px;
      }
    }

    &__title {
      margin-bottom: 25px;
      font: 600 24px/32px $SFProDisplay;
      text-align: center;
      color: #fff;
    }

    &__form {
      padding-top: 20px;


    }

    &__input {
      margin-bottom: 16px;
      position: relative;

      input {
        border-radius: 8px;
        width: 100%;
        height: 50px;
        padding: 9px 16px;
        font: 600 16px/32px $SFProDisplay;
      }
    }

    &__rating {
      .rating {
        display: flex;
        flex-flow: row-reverse wrap;
        max-width: 290px;
        margin: 0 auto;
        justify-content: space-between;
        font-size: 45px;
        overflow: hidden;
      }

      .rating input {
        float: right;
        opacity: 0;
        position: absolute;
      }

      .rating a,
      .rating label {
        float: right;
        color: #aaa;
        text-decoration: none;
        -webkit-transition: color .4s;
        -moz-transition: color .4s;
        -o-transition: color .4s;
        transition: color .4s;
      }

      .rating label:hover ~ label,
      .rating input:focus ~ label,
      .rating label:hover,
      .rating a:hover,
      .rating a:hover ~ a,
      .rating a:focus,
      .rating a:focus ~ a {
        color: orange;
        cursor: pointer;
      }

      .rating2 {
        direction: rtl;
      }

      .rating2 a {
        float: none
      }
    }
  }

  &-container {
    padding: 16px;
    display: flex;
    flex-flow: column nowrap;
    gap: 16px;
    //max-height: calc(var(--app-height) - 150px);
    //box-shadow: 0 -5px 50px rgba(0, 0, 0, 0.75);
    //overflow-y: scroll;
  }

  &-booking {
    box-shadow: 0 -5px 50px rgba(0, 0, 0, 0.75);
    position: sticky;
    bottom: 0;
    z-index: 2;

    .btn {
      padding: 20px;
      width: 100%;
      font-style: normal;
      font: 700 20px/24px $SFProDisplay;
      color: #fff;
    }
  }

  &-options {
    padding: 24px 16px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
  }

  &-option {
    position: relative;
    padding-left: 29px;

    &::before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    + .order-option {
      margin-top: 16px;
    }

    dt {
      color: #c3c7db;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    dd {
      margin-top: 7px;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #fff;
    }
  }

  &-box {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    padding: 16px 14px;
    display: grid;
    grid-template-columns: 80px 1fr;
    align-items: center;
    grid-template-areas: "image model" "image number";
    column-gap: 21px;
    row-gap: 6px;

    &__car {
      grid-area: image;
      height: 80px;
      position: relative;
      overflow: hidden;
      border-radius: 4px;

      img {
        @include img_center();
        max-width: 200px;
        max-height: 200px;
      }
    }

    &__model {
      grid-area: model;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, .75);
    }

    &__number {
      grid-area: number;
      font: 700 40px/48px $SFProDisplay;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #fff;
    }

  }

  &-date {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
  }

}
