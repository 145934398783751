.dev-block {
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  max-width: 200px;
  padding: 10px;
  background: red;
  display: flex;
  flex-flow: column;
  gap: 8px;

  a {
    color: #fff;
  }

}
