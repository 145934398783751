@import "fonts";
@import '../../node_modules/css-reset-and-normalize/scss/reset-and-normalize';
@import "../vendor/picker.min.css";
@import "vars";
@import "mixins";
@import "buttons";
@import "header";
@import "order";
@import "submission";
@import "progress_bar";
//@import "rolldate";
@import "booking";
@import "dev"; // todo remove

:root {
  --app-text-color: #fff;
  --app-height: 100vh;
  --app-width: 414px;
  --app-scroll-size: 4px;
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
}

*::-webkit-scrollbar {
  height: var(--app-scroll-size);
  width: var(--app-scroll-size);
}

*::-webkit-scrollbar-track {
  height: var(--app-scroll-size);
  width: var(--app-scroll-size);
  background-color: rgba(0, 0, 0, 0.25);
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.65);
  border-radius: calc(var(--app-scroll-size) / 2);
}

html {
  overflow-x: clip;
}

body {
  width: 100vw;
  overflow-x: clip;
  position: relative;
  color: var(--app-text-color);
  font-family: $Roboto;
  background: linear-gradient(180.05deg, #081a90 -4.3%, #000a4e 99.96%);
}

.page {
  min-height: var(--app-height);
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  padding-top: 320px;
}

.container {
  margin: 0 auto;
  max-width: var(--app-width);
}

.overlay {
  transition: opacity .3s;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);

  &.__show {
    pointer-events: all;
    opacity: 1;
  }
}

.hidden {
  display: none !important;
}

.__w-100 {
  width: 100%;
}
