.submission {
  pointer-events: all;

  &-top {
    padding: 18px 0;
    border-radius: 20px 20px 0 0;
    background: #f3f3f3;
  }

  &-time {

    input {
      position: absolute;
    }
  }

  &-picker {

    .picker-list {
      margin: -6.5rem 0 -6.5rem;
    }

    .picker-grid {
      //display: flex;
      //flex-flow: row nowrap;
      //justify-content: space-around;
    }

    .picker-cell {
      border-left: 0;
      //flex: 1 1 100%;

      &::after {
        margin-top: 2rem;
      }

      &::before {
        margin-bottom: 2rem;
      }

      &::after,
      &::before {
        background: rgba(243, 243, 243, 0.9);
      }

      &.picker-years {

        .picker-cell__body {
          margin-right: -20px;
          z-index: 2;
        }

      }


      &.picker-days {

        .picker-cell__body {
          margin-right: -30px;
          z-index: 2;
        }

      }

      &.picker-months {

        .picker-cell__body {
          margin-right: -40px;
        }

      }
    }

    .picker-body {
      position: relative;
    }

    .picker-item {
      height: 74px;
      font: 700 56px/1 $SFProDisplay;
      text-align: center;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #rolldate-year {
      min-width: 19vw;
    }

    &.__today,
    &.__tomorrow,
    &.__time {

      .picker-body {
        &::before {
          z-index: 10;
          content: ':';
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -8px;
          transform: translate(-50%, -50%);
          font: 700 64px / 24px $SFProDisplay;
          color: #000;
        }

        .picker-days,
        .picker-months,
        .picker-years {
          display: none;
        }
      }

      .rolldate-wrapper {
        position: relative;

        &::before {
          z-index: 10;
          content: ':';
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -8px;
          transform: translate(-50%, -50%);
          font: 700 64px / 24px $SFProDisplay;
          color: #000;
        }

        .picker-cell {
          border-left: 0;
        }

        #rolldate-year,
        #rolldate-month,
        #rolldate-day {
          position: absolute;
          top: 0;
          opacity: 0;
          width: 0;
          pointer-events: none;
          overflow: hidden;
          margin-left: -100vw;
        }
      }
    }

    &.__date {

      .picker-body {

        .picker-minutes,
        .picker-hours {
          display: none;
        }

      }

      .rolldate-wrapper {
        #rolldate-hour,
        #rolldate-min {
          position: absolute;
          top: 0;
          opacity: 0;
          width: 0;
          pointer-events: none;
          overflow: hidden;
          margin-left: -100vw;
        }
      }
    }
  }

  .title {
    color: #000;
  }

  .text {
    color: rgba(0, 0, 0, .5);
  }

  &-date {
    padding: 9px 12px 12px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    background: #f3f3f3;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .btn {
      border-radius: 8px;
      padding: 8px 12px;
    }
  }

  &-time {
    position: relative;
    height: 164px;
    overflow: hidden;
  }

  &-confirm {
    background: #f3f3f3;
    display: grid;
    gap: 18px;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 18px 16px 16px;

    .btn {
      border-radius: 8px;
      padding: 15px;
      font: 700 20px/ 24px $SFProDisplay;
      text-align: center;
      letter-spacing: 0.5px;
    }
  }

}
